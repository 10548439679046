import React from "react";
import SEO from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import FlexibleLayout from "../../components/FlexibleLayout";
import SocialShare from "../../components/SocialShare/SocialShare";

const work = ({ data, location }) => {
  const {
    page: {
      title,
      uri,
      slug,
      theme,
      seo,
      flexchildren
    }
  } = data;

  return (
    <>
      <SEO seoData={seo} uri={uri} />
      <Layout bodyClass={slug} theme={theme} location={location}>
        <SocialShare title={title} url={location.href} />
        {flexchildren && flexchildren.map((layout, index) => {
            return (
              <FlexibleLayout
                key={index}
                layout={layout}
                backDetails={{ uri: "/work/", title: "work" }}
              />
            );
          })}
      </Layout>
    </>
  );
};

export default work;
