import { useTwitch, useTwitchStacked1, useTwitchStacked2, useTwitchStacked3, useTwitchStacked4 } from "../../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "default",
      },
      title: "Twitch",
      uri: "/work/twitch/",
      // dateGmt: "2021-02-22T21:23:26",
      slug: "twitch",
      // modifiedGmt: "2021-05-18T21:08:29",
      seo: {
        canonical: "",
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Twitch - Vivid",
        opengraphType: "article",
        title: "Twitch - Vivid",
        twitterDescription: "",
        twitterTitle: "",
        twitterImage: null,
      },
      flexchildren: [
        {
          __typename: "HeroCta",
          backgroundGradientLight: "#a193e2",
          backgroundGradientDark: "#5e449e",
          backgroundImagePosition: "right",
          backgroundSize: "contain",
          cta: null,
          ctaVariant: "light",
          description:
            "Helping an entertainment giant engage developers and build new revenue streams",
          descriptionColor: null,
          title: "Reimagining the live-streaming experience",
          titleColor: null,
          rightImage: {
            altText: "",
            useImage: useTwitch,
            localFile: {
              publicURL:
                "/static/bb3383a33e242b17c21fd2d5713f79c3/dfuzr_success_story_twitch_ui_design_thumbnail_V2.png",
              extension: "png",
              childImageSharp: {
                fluid: {
                  base64:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEc0lEQVQ4y3WUfUwbZRzHf9dDjH+YuJFsMf7BWwERy2Agggyw2oCIkRcdY7ztD820kIVFXWARX2YEgQ3nwksgmQQ2cBm0paW8Cw5BZLysDDZmKYwWuqsUSgu0pUd7vcc7EjPr2C957i655/k83+/v+9wB/KdOxnVAmXAEzrzdA6ciZBhCCKsTjsOFqL6Iodr5P7SzOk+0jWDltgFj5//ylRyeWkmvSyA3vgsEviLwd2+EhpIpt+wACeDwzfOtxXdvLo6vItOW4QizCeza7Fi8ey6wz/sWP0oEhWeG4bhADgnhkj1l53N+hXSuKEpadX/BQGwj0mFFTkS9c29xBvpkA25wAiAkmgc8XvB+yBrITesHQagYO4Bd3dv50/e6zw2IVchmsyEbaSXZe790SCZMKjr976oTyZkAnH01XoEPE7owDKrBHWoPX/hiVH5foUMO2oYsFjNl3rYgp9Pp6JL1oMqLlye4EMMuwtnLYb+DkJGR7Yqr/mkC7kyu4CNDamhve1C8sryG7HbL7vKC1rmm30C7u7s0O6anFai8vFwfc1QgCfAJCgn0DYbgoFDOE72knFuwbTbi1p1NIHe3Tq/p9MiwtuHQ64yI0OppkrTRpI1EarWGLi0ppQs/+xLlZOemZZ7MgtycU/hdxQNX4LySAIPBiJs2TWA0mlLX/t5Aq8S6Y3VFT+uINUSSJGPdSj96RKDq6hp76Q+lSCgUFldUVEBDQwMeFRntCiTJHbBYLDiTItDIydcT62hJpXVaLDbaaDAzwezQVquVtjO2W5pbHIxtlJ+Xf3NaoYD19XWOh8dBV6DJZAKz2cwxm7fBYjWHMWoo04aFdjjsFOWkmBahPdjc3Byqqamh6uvrUUHB2VmEHHsn4uOPPoG6urrHQI1GwyrEtra2gBlcxqKJohxssqxVamxsjGpqaqJbW1uRUql0yuVyVFhYpE+IT3yRzxfAsWOx2LWma64qjUYjpiN0QBDEoc3NTSsD005NTY3W1tYiiUSCZmZm0Pj4OJJKpXRvb6+d6R3y9PTi+3j7Qnj4a3hjY6MrUDW/ADPT92D6zsxz80pVJmMFb25uSVepVNTS0lK/WCyiOjs7nc3NzU6hMA8xQRAB/oGv+HhzgfdqMKejo/P/Satg4vYkjAyPgkatYVLfAEKr82bAh5jXHpWVldasrBwUFHQE+XED6z5Iy3kh8OUg8PcL5Hh5eYFYLHEFPlxcAvVDNfPh74Dyr3m34d9Hn7l+/cZe01NS07+Le1OAeLyjs4mJyXx2fvL7GRAaEs7hcgPA29sHpO2yx7Cuzj7IzyuAttZ2EItk2OTENIjapFBaepFfVlapvnTpCqqouPw1A3eLjIwFH58APDXlOJb0bjJEvxEHqSnprupY4PmiYuiQdWMyaRf8OTrx0uDg8I+d8l5Nb8/ADbmsO6iqqg6+/7YEBIJEXDmlgM/PngMWyK57olhgu6RjTxWj8EB3V38WA0uamlA8e+u3Ebg1OMycs5/x2Ji3sJXlDQgLi3jqf/Ufa+CdPgfOutcAAAAASUVORK5CYII=",
                  aspectRatio: 1.1173184357541899,
                  src: "/static/bb3383a33e242b17c21fd2d5713f79c3/f9ff4/dfuzr_success_story_twitch_ui_design_thumbnail_V2.png",
                  srcSet:
                    "/static/bb3383a33e242b17c21fd2d5713f79c3/5224a/dfuzr_success_story_twitch_ui_design_thumbnail_V2.png 200w,\n/static/bb3383a33e242b17c21fd2d5713f79c3/d786d/dfuzr_success_story_twitch_ui_design_thumbnail_V2.png 400w,\n/static/bb3383a33e242b17c21fd2d5713f79c3/f9ff4/dfuzr_success_story_twitch_ui_design_thumbnail_V2.png 800w,\n/static/bb3383a33e242b17c21fd2d5713f79c3/28af6/dfuzr_success_story_twitch_ui_design_thumbnail_V2.png 1086w",
                  srcWebp:
                    "/static/bb3383a33e242b17c21fd2d5713f79c3/b0751/dfuzr_success_story_twitch_ui_design_thumbnail_V2.webp",
                  srcSetWebp:
                    "/static/bb3383a33e242b17c21fd2d5713f79c3/9e195/dfuzr_success_story_twitch_ui_design_thumbnail_V2.webp 200w,\n/static/bb3383a33e242b17c21fd2d5713f79c3/40a1d/dfuzr_success_story_twitch_ui_design_thumbnail_V2.webp 400w,\n/static/bb3383a33e242b17c21fd2d5713f79c3/b0751/dfuzr_success_story_twitch_ui_design_thumbnail_V2.webp 800w,\n/static/bb3383a33e242b17c21fd2d5713f79c3/eb128/dfuzr_success_story_twitch_ui_design_thumbnail_V2.webp 1086w",
                  sizes: "(max-width: 800px) 100vw, 800px",
                  presentationWidth: 800,
                },
              },
            },
          },
          backgroundImage: null,
          overlapSection: null,
        },
        {
          __typename: "Overview",
          client: "Twitch",
          description:
            "In less than a decade since its founding, Twitch has become the dominant live-streaming platform for gamers, entertainers, and their communities. As of late 2020, the platform boasted over 15 million daily active users. But like many other high-growth platforms, Twitch was failing to capitalize on the full value of their community, and needed help engaging and monetizing their massive real-time audiences. <br />\r\n<br />\r\nWe joined forces with Twitch in 2018 to increase developer adoption of their Extensions program, which monetizes captive audiences through interactive stream experiences. Our comprehensive engagement strategy spanned multiple product teams and included an overhaul of the developer website, rebuild of the developer portal, new Extensions, as well as promotion of these initiatives.<br />\r\n<br />\r\n",
          industry: "Internet Publishing and Broadcasting",
          solutions: "Strategy, Design, Development, Copywriting",
          title: "Overview",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useTwitchStacked1,
                localFile: {
                  publicURL:
                    "/static/b503cea75c7c884e82325504e306af95/Twitch-Success-Story-5-copy-1.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBAv/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABxP3ptRG//8QAHBABAAIBBQAAAAAAAAAAAAAAAgABAxESExQi/9oACAEBAAEFAmntxinOMaWfRxVc65n/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECIREiMZL/2gAIAQEABj8CjGjdoxleh2O3w6z/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMUFh0fH/2gAIAQEAAT8heBQdZaXE1yVgUGJNyN1yDByeEPi+p//aAAwDAQACAAMAAAAQsC//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EKsIf//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESFB/9oACAECAQE/EB1iEdv/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFhkf/aAAgBAQABPxAsJ2hTenypjbcDUtL2ZVGni2oL0ggBgMOomoJfDjTryU4H/9k=",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/b503cea75c7c884e82325504e306af95/a1eb1/Twitch-Success-Story-5-copy-1.jpg",
                      srcSet:
                        "/static/b503cea75c7c884e82325504e306af95/4fa6c/Twitch-Success-Story-5-copy-1.jpg 200w,\n/static/b503cea75c7c884e82325504e306af95/64b17/Twitch-Success-Story-5-copy-1.jpg 400w,\n/static/b503cea75c7c884e82325504e306af95/a1eb1/Twitch-Success-Story-5-copy-1.jpg 800w,\n/static/b503cea75c7c884e82325504e306af95/a6c62/Twitch-Success-Story-5-copy-1.jpg 1200w,\n/static/b503cea75c7c884e82325504e306af95/70ff9/Twitch-Success-Story-5-copy-1.jpg 1600w,\n/static/b503cea75c7c884e82325504e306af95/cfa73/Twitch-Success-Story-5-copy-1.jpg 2260w",
                      srcWebp:
                        "/static/b503cea75c7c884e82325504e306af95/b0751/Twitch-Success-Story-5-copy-1.webp",
                      srcSetWebp:
                        "/static/b503cea75c7c884e82325504e306af95/9e195/Twitch-Success-Story-5-copy-1.webp 200w,\n/static/b503cea75c7c884e82325504e306af95/40a1d/Twitch-Success-Story-5-copy-1.webp 400w,\n/static/b503cea75c7c884e82325504e306af95/b0751/Twitch-Success-Story-5-copy-1.webp 800w,\n/static/b503cea75c7c884e82325504e306af95/a7c53/Twitch-Success-Story-5-copy-1.webp 1200w,\n/static/b503cea75c7c884e82325504e306af95/80926/Twitch-Success-Story-5-copy-1.webp 1600w,\n/static/b503cea75c7c884e82325504e306af95/b6083/Twitch-Success-Story-5-copy-1.webp 2260w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useTwitchStacked2,
                localFile: {
                  publicURL:
                    "/static/c259943a027596cc67ac715cf6575d50/Twitch-Success-Story-4-2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAanVIgwjP//EABoQAAMBAAMAAAAAAAAAAAAAAAECAwAREiH/2gAIAQEAAQUC7IHom80ABOrtxv/EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8BTLj/xAAYEQACAwAAAAAAAAAAAAAAAAAAARESMf/aAAgBAgEBPwFRhRH/xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhEBIxQf/aAAgBAQAGPwKLOs8NosanH//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExQcGR/9oACAEBAAE/Ic6ocX2KaANmyUcX9Q+TfNzmK/Iz/9oADAMBAAIAAwAAABB4L//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAEDAQE/EJB//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EAHEf//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExUXGBocH/2gAIAQEAAT8QKTTgM96X6gN1w0XHnOG9jVQJ3XZeaqkXKERnR8v2Jvs//9k=",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/c259943a027596cc67ac715cf6575d50/a1eb1/Twitch-Success-Story-4-2.jpg",
                      srcSet:
                        "/static/c259943a027596cc67ac715cf6575d50/4fa6c/Twitch-Success-Story-4-2.jpg 200w,\n/static/c259943a027596cc67ac715cf6575d50/64b17/Twitch-Success-Story-4-2.jpg 400w,\n/static/c259943a027596cc67ac715cf6575d50/a1eb1/Twitch-Success-Story-4-2.jpg 800w,\n/static/c259943a027596cc67ac715cf6575d50/a6c62/Twitch-Success-Story-4-2.jpg 1200w,\n/static/c259943a027596cc67ac715cf6575d50/70ff9/Twitch-Success-Story-4-2.jpg 1600w,\n/static/c259943a027596cc67ac715cf6575d50/cfa73/Twitch-Success-Story-4-2.jpg 2260w",
                      srcWebp:
                        "/static/c259943a027596cc67ac715cf6575d50/b0751/Twitch-Success-Story-4-2.webp",
                      srcSetWebp:
                        "/static/c259943a027596cc67ac715cf6575d50/9e195/Twitch-Success-Story-4-2.webp 200w,\n/static/c259943a027596cc67ac715cf6575d50/40a1d/Twitch-Success-Story-4-2.webp 400w,\n/static/c259943a027596cc67ac715cf6575d50/b0751/Twitch-Success-Story-4-2.webp 800w,\n/static/c259943a027596cc67ac715cf6575d50/a7c53/Twitch-Success-Story-4-2.webp 1200w,\n/static/c259943a027596cc67ac715cf6575d50/80926/Twitch-Success-Story-4-2.webp 1600w,\n/static/c259943a027596cc67ac715cf6575d50/b6083/Twitch-Success-Story-4-2.webp 2260w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useTwitchStacked3,
                localFile: {
                  publicURL:
                    "/static/c99006abc5556715da5ad2ed6d793527/Twitch-Success-Story-5c-1.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABjYZaUwPP/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxITMv/aAAgBAQABBQJcZHOWe1tOFtMsOn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8B3Ff/xAAbEAACAwADAAAAAAAAAAAAAAAAAQIRMTJRkf/aAAgBAQAGPwJWjB0JVHOjjHwbP//EABkQAQEBAQEBAAAAAAAAAAAAAAERAFEhgf/aAAgBAQABPyEeNs5oEB9NAAhvZOccK5OoAvCb/9oADAMBAAIAAwAAABBvP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAwEBPxASdv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QMOS1W//EAB0QAQADAAEFAAAAAAAAAAAAAAEAESExQVFhkbH/2gAIAQEAAT8QJqRV65RI+bFlWGGHHEGohAtnuJUwL0v2VposIcdif//Z",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/c99006abc5556715da5ad2ed6d793527/a1eb1/Twitch-Success-Story-5c-1.jpg",
                      srcSet:
                        "/static/c99006abc5556715da5ad2ed6d793527/4fa6c/Twitch-Success-Story-5c-1.jpg 200w,\n/static/c99006abc5556715da5ad2ed6d793527/64b17/Twitch-Success-Story-5c-1.jpg 400w,\n/static/c99006abc5556715da5ad2ed6d793527/a1eb1/Twitch-Success-Story-5c-1.jpg 800w,\n/static/c99006abc5556715da5ad2ed6d793527/a6c62/Twitch-Success-Story-5c-1.jpg 1200w,\n/static/c99006abc5556715da5ad2ed6d793527/70ff9/Twitch-Success-Story-5c-1.jpg 1600w,\n/static/c99006abc5556715da5ad2ed6d793527/cfa73/Twitch-Success-Story-5c-1.jpg 2260w",
                      srcWebp:
                        "/static/c99006abc5556715da5ad2ed6d793527/b0751/Twitch-Success-Story-5c-1.webp",
                      srcSetWebp:
                        "/static/c99006abc5556715da5ad2ed6d793527/9e195/Twitch-Success-Story-5c-1.webp 200w,\n/static/c99006abc5556715da5ad2ed6d793527/40a1d/Twitch-Success-Story-5c-1.webp 400w,\n/static/c99006abc5556715da5ad2ed6d793527/b0751/Twitch-Success-Story-5c-1.webp 800w,\n/static/c99006abc5556715da5ad2ed6d793527/a7c53/Twitch-Success-Story-5c-1.webp 1200w,\n/static/c99006abc5556715da5ad2ed6d793527/80926/Twitch-Success-Story-5c-1.webp 1600w,\n/static/c99006abc5556715da5ad2ed6d793527/b6083/Twitch-Success-Story-5c-1.webp 2260w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useTwitchStacked4,
                localFile: {
                  publicURL:
                    "/static/665d7067b45f0f14fd37c791bfcac6ab/Twitch-Success-Story-1-copy-1.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFi2vOqRIy//8QAGRAAAgMBAAAAAAAAAAAAAAAAAgMAAQQT/9oACAEBAAEFAmdTNVNAtWkltZF1GBRH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EAB4QAQACAgEFAAAAAAAAAAAAAAEAEQIhEgMjUWFx/9oACAEBAAY/Au25VUvqKjrbOOPiaU+Ry5K+4rc//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERITFR/9oACAEBAAE/IeaNJwSOQFDN8SUVtaqUodp6jUalTP/aAAwDAQACAAMAAAAQxz//xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAEDAQE/EFYNYf/EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAgEBPxDRgS3/xAAeEAEBAAICAgMAAAAAAAAAAAABEQBBIYExUWFxsf/aAAgBAQABPxBJIoinMUZesVeUUx8j6PCd5y9oUba/kwNXAWMdXoxyEWHBfJd5Yq6xJ639Z//Z",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/665d7067b45f0f14fd37c791bfcac6ab/a1eb1/Twitch-Success-Story-1-copy-1.jpg",
                      srcSet:
                        "/static/665d7067b45f0f14fd37c791bfcac6ab/4fa6c/Twitch-Success-Story-1-copy-1.jpg 200w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/64b17/Twitch-Success-Story-1-copy-1.jpg 400w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/a1eb1/Twitch-Success-Story-1-copy-1.jpg 800w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/a6c62/Twitch-Success-Story-1-copy-1.jpg 1200w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/70ff9/Twitch-Success-Story-1-copy-1.jpg 1600w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/cfa73/Twitch-Success-Story-1-copy-1.jpg 2260w",
                      srcWebp:
                        "/static/665d7067b45f0f14fd37c791bfcac6ab/b0751/Twitch-Success-Story-1-copy-1.webp",
                      srcSetWebp:
                        "/static/665d7067b45f0f14fd37c791bfcac6ab/9e195/Twitch-Success-Story-1-copy-1.webp 200w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/40a1d/Twitch-Success-Story-1-copy-1.webp 400w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/b0751/Twitch-Success-Story-1-copy-1.webp 800w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/a7c53/Twitch-Success-Story-1-copy-1.webp 1200w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/80926/Twitch-Success-Story-1-copy-1.webp 1600w,\n/static/665d7067b45f0f14fd37c791bfcac6ab/b6083/Twitch-Success-Story-1-copy-1.webp 2260w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
